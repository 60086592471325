// import React from 'react';
// import Home from '../src/pages/Home/home.jsx';
// import Footer from '../src/pages/Footer/footer.jsx';
// import About from './pages/About/About.jsx';
// import Services from './pages/Services/Services.jsx';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Contactus from "./pages/ContactUs/contactUs.jsx";
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import './fonts.css';
// const theme = createTheme({
//   typography: {
//     h1: {
//       fontWeight: 600,
//       fontSize: '2.25rem',
//       lineHeight: 2.75,
//     },
//     h2: {
//       fontWeight: 600,
//       fontSize: '1.625rem',
//       lineHeight: 2.125, 
//     }
//   }
// });

// const App = () => {
 
//   // console.log(window.location.pathname);
 
  
//   return (
//     <ThemeProvider theme={theme}>
//      <Router>
//      <Routes>
//        <Route exact path="/" element={< Home />} />
//        <Route path="/home" element={<Home />} />
//        <Route path="/about" element={<About />} />
//        <Route path="/service" element={<Services />} />
//        <Route path="/contactUs" element={<Contactus />} />
//      </Routes>
//       <Footer />
//    </Router>
//    </ThemeProvider>
//   );
// };

// export default App;









//website_under_progress_theme
import React from 'react';
import newlogo from '../src/images/new_logo.png'

const UnderConstruction = () => {
  // Add global styles to ensure full viewport coverage
  React.useEffect(() => {
    // Reset body and html styles
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.minHeight = '100vh';
    document.documentElement.style.margin = '0';
    document.documentElement.style.padding = '0';
    document.documentElement.style.minHeight = '100vh';
    
    // Cleanup function
    return () => {
      document.body.style.margin = '';
      document.body.style.padding = '';
      document.body.style.minHeight = '';
      document.documentElement.style.margin = '';
      document.documentElement.style.padding = '';
      document.documentElement.style.minHeight = '';
    };
  }, []);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      minWidth: '100vw',
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      backgroundColor: '#f8f9fa',
      fontFamily: "'Helvetica Neue', Arial, sans-serif",
      background: 'linear-gradient(135deg, #ffffff 0%, #f0f2f5 100%)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'auto',
    },
    content: {
      width: '100%',
      maxWidth: '1200px',
      textAlign: 'center',
      padding: '2rem',
      position: 'relative',
      zIndex: 2,
      boxSizing: 'border-box',
    },
    logoContainer: {
      marginBottom: '3rem',
      animation: 'fadeInDown 0.8s ease-out',
    },
    logo: {
      width: '280px',
      maxWidth: '100%',
      height: 'auto',
    },
    title: {
      fontSize: 'clamp(2rem, 5vw, 3.5rem)',
      color: '#2d3748',
      margin: '0 0 1.5rem 0',
      padding: 0,
      fontWeight: '700',
      letterSpacing: '-0.5px',
      lineHeight: 1.2,
      animation: 'fadeInUp 0.8s ease-out',
    },
    subtitle: {
      fontSize: 'clamp(1.1rem, 2vw, 1.4rem)',
      color: '#4a5568',
      maxWidth: '800px',
      margin: '0 auto 3rem',
      padding: 0,
      lineHeight: 1.6,
      animation: 'fadeInUp 1s ease-out',
    },
    highlight: {
      color: '#00A9B5',
      fontWeight: '600',
    },
    footer: {
      marginTop: '4rem',
      fontSize: '1rem',
      color: '#718096',
      animation: 'fadeInUp 1.2s ease-out',
    },
    link: {
      color: '#00A9B5',
      textDecoration: 'none',
      transition: 'color 0.3s ease',
      padding: '0 2px',
    },
    decorativeShape: {
      position: 'absolute',
      borderRadius: '50%',
      background: 'linear-gradient(135deg, rgba(0,169,181,0.1) 0%, rgba(0,169,181,0.05) 100%)',
      zIndex: 1,
    },
    shape1: {
      width: '300px',
      height: '300px',
      top: '-50px',
      right: '-50px',
    },
    shape2: {
      width: '200px',
      height: '200px',
      bottom: '-30px',
      left: '-30px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={{...styles.decorativeShape, ...styles.shape1}} />
      <div style={{...styles.decorativeShape, ...styles.shape2}} />
      
      <div style={styles.content}>
        <div style={styles.logoContainer}>
          <img
            src={newlogo}
            alt="STR SOFTWARES Logo"
            style={styles.logo}
          />
        </div>
        
        <h1 style={styles.title}>Our Website is Under Construction</h1>
        
        <p style={styles.subtitle}>
          We're working hard to bring you an{' '}
          <span style={styles.highlight}>innovative software experience</span>.
          <br />
          Stay tuned for our launch!
        </p>
        
        <footer style={styles.footer}>
          <p>&copy; {new Date().getFullYear()} STR SOFTWARES. All rights reserved.</p>
          <p>
            For inquiries, please contact:{' '}
            <a href="mailto:nitin@str-softwares.com" style={styles.link}>
              nitin@str-softwares.com
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
};

export default UnderConstruction;